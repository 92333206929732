import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

const BlogNew = ({ location }) => (
  <Layout location={location}>
    <Meta title=" | ruttl " url="https://ruttl.com/" />
  </Layout>
);

BlogNew.propTypes = {
  location: PropTypes.object,
};

export default BlogNew;
